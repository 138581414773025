import { IGameCenterDetail } from '@flarie/common';
import { ReactNode } from 'react';
import { useGameCenterSettingStore } from 'store/GameCenterSettingStore';
import { isGCValidOrExpired } from 'utils/is-gc-valid-or-expired';
import { GameCenterExpired } from 'components/game-center-expired/GameCenterExpired';
import { PageHeader } from 'components/page-header/PageHeader';
import { DynamicFontLoader } from 'components/dynamic-font-loader/DynamicFontLoader';
import { useDimension } from 'hooks/useDimension';
import { getPageWrapperStyle } from './styles';

export const PageWrapper = ({ children }: { children: ReactNode }) => {
  const detail = useGameCenterSettingStore((state) => state.detail as IGameCenterDetail);
  const styles = getPageWrapperStyle(detail || ({} as IGameCenterDetail));
  const { expired, notAvailable } = isGCValidOrExpired(detail);

  const renderChildren = () => {
    if (expired) return <GameCenterExpired> This Game center is expired </GameCenterExpired>;
    if (notAvailable) return <GameCenterExpired> This Game center is not yet available </GameCenterExpired>;
    return children;
  };

  // className="relative mx-auto flex items-center justify-center"
  return (
    <>
      <PageHeader
        backgroundImg={detail.webBackgroundImage}
        backgroundColor={detail.webBackgroundColor}
        fontFamily="global-font"
      />

      <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, overflowY: 'auto' }}>
        <DynamicFontLoader fontId={detail.font} fontName="detail-font-family" className="w-full">
          <div style={styles.pageBackground}>{renderChildren()}</div>
        </DynamicFontLoader>
      </div>
    </>
  );
};
