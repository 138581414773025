export const getSectionStyles = (width: number): any => {
  return {
    containerStyle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
      height: 56,
      borderTopLeftRadius: 16,
      borderTopRightRadius: 16,
      backgroundColor: 'white',
      cursor: 'pointer',
    },
    stickyTextStyle: {
      color: '#000',
      fontSize: 18,
    },
  };
};
