import {
  ComponentType,
  FormInputType,
  IGameCenter,
  IGameCenterFormInput,
  IGameCenterUserBase,
  IPlayer,
  UserBaseIdentifierType,
} from '@flarie/common';
import { HTTPRequestType } from 'enums/HTTPRequestType';
import { WebHookQueryType } from 'enums/WebHookQueryType';
import { IGameCenterDto } from 'interfaces/GameCenterDto';
import { IPlayerGameCenterDetail } from 'interfaces/PlayerGameCenterDetail';
import { getLocalGameCenterData } from 'services/LocalStorageService';
import { getPropertyKeyFromType } from './get-property-key-from-type';

export const addThousandSeparator = (value: number) => {
  return (Number(value)?.toLocaleString('en') || value || 0).toString().replace(/,/g, ' ');

  // return Number(value)?.toLocaleString('en') || value || 0;
};

export const getPlayerIdIdentifierByGameCenter = (
  gameCenterId: string,
  identifierType?: UserBaseIdentifierType,
): IGameCenterDto => {
  const localData = getLocalGameCenterData(gameCenterId);
  const playerId = localData?.playerId;
  let identifierValue = playerId;

  if (identifierType === UserBaseIdentifierType.URL_PARAMETER) {
    identifierValue = localData?.uuid;
  }

  if (identifierType === UserBaseIdentifierType.USER_INPUT) {
    identifierValue = localData?.email;
  }

  const payload: IGameCenterDto = {
    gameCenterId,
    playerId: playerId || '',
    identifierValue: identifierValue || '', // TODO: we need to improve it
  };

  return payload;
};

export const removeEmptyStringProperties = <T>(obj: { [key: string]: any }): T => {
  const payload = { ...obj } as any;
  // eslint-disable-next-line no-restricted-syntax
  for (const key in payload) {
    if (typeof payload[key] === 'string' && payload[key].trim() === '') {
      delete payload[key];
    }
  }
  return payload;
};

export const formatPlayerResponse = (playerData: IPlayer, parameters: IGameCenterFormInput[] = []): IPlayer => {
  const paramPrefix = 'param';
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(playerData)) {
    if (
      key.startsWith(paramPrefix) &&
      parameters?.find((item) => item.componentType === ComponentType.CHECKBOX && item.type.toLowerCase() === key)
    ) {
      (playerData as any)[key] = Boolean(value).toString();
    }
  }

  return playerData;
};

// TODO: API response has been changed. than's why I have to support Backward Compatibility. we will remove this method to the next sprint.
export const supportBackwardCompatibilityForGamificationLock = (
  playerGameCenterDetail: IGameCenter & IPlayerGameCenterDetail,
): IPlayerGameCenterDetail => {
  if (playerGameCenterDetail?.gameCenter) {
    return playerGameCenterDetail;
  }
  const playerGameCenterResponse: IPlayerGameCenterDetail = {
    gameCenter: playerGameCenterDetail,
    moduleLock: {
      games: [],
      rewards: [],
    },
  };
  return playerGameCenterResponse;
};

export const getWebHookEventTypeByUserBase = (
  userBase: IGameCenterUserBase,
  type: HTTPRequestType,
  isAdditionalInfoForm?: boolean,
): WebHookQueryType => {
  if (userBase && userBase.isEnable) {
    if (type === HTTPRequestType.POST && userBase.parameters.length === 1) {
      return WebHookQueryType.GAME_CENTER_SIGN_UP;
    }
    if (type === HTTPRequestType.PATCH && !isAdditionalInfoForm) {
      return WebHookQueryType.GAME_CENTER_SIGN_UP;
    }
    if (type === HTTPRequestType.PATCH && isAdditionalInfoForm) {
      return WebHookQueryType.GAME_CENTER_ADDITIONAL_FORM_SUBMISSION;
    }
  }
  return WebHookQueryType.DEFAULT;
};
