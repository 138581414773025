import styled from 'styled-components';

export const DrawerStyled = styled.div<{ isCollapsed: boolean }>`
  position: absolute;
  background-color: white;
  padding: 4px;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex: 1;
  z-index: 100;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.15);
  transform: ${(props): any => (props.isCollapsed ? `translateY(-97%)` : '')};
  transition: 300ms ease-in-out;
  display: grid;
`;

export const ImageContainerStyled = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  backdrop-filter: red;
  padding: 16px 0;
  background-image: url('/drawer-bg.png');
  height: 88px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const TextContainerStyled = styled.div`
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
  font-weight: 600;
  font-size: 13px;
  display: flex;
  flex: 1;
  text-align: center;
  justify-content: center;
  align-items: center;
  line-height: 1.2;
  color: white;
`;

export const DrawerCollapseStyled = styled.div<{ isCollapsed: boolean }>`
  width: 80px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  transform: translateY(80%);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

  svg {
    transition: 300ms ease-in-out;
    transform: ${(props): any => (props.isCollapsed ? 'rotate(180deg)' : '')};
  }
`;

export const LearnMoreButtonStyled = styled.button`
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
  font-weight: 600;
  font-size: 14px;
  width: 128px;
  height: 36px;
  border-radius: 32px;
  display: block;
  border: 0;
  margin-top: 8px;
  color: #1e4377;

  &&& {
    background-color: #ffffff;
  }
`;
