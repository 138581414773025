import { ReactNode, useEffect } from 'react';
import ReactModal from 'react-modal';
import { BackIcon } from 'components/icons/BackIcon';
import { CloseIcon } from 'components/icons/CloseIcon';
import { useResponsiveScale } from 'hooks/useResponsiveScale';
import { BaseDimension } from 'common/constants';
import { transform } from 'lodash';
import { useDimension } from 'hooks/useDimension';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  showBack?: boolean;
  onBackClick?: () => void;
  showFooter?: boolean;
  showClose?: boolean;
  className?: string;
  contentClassName?: string;
  outerContentClassName?: string;
  overlayStyles?: object;
  contentStyles?: object;
  modalFooter?: ReactNode;
  isFloatingModal?: boolean;
  closeIconColor?: string;
  isGameModal?: boolean;
}

export const Modal = ({
  isOpen,
  onClose,
  children,
  showBack = false,
  onBackClick,
  showFooter = true,
  showClose = true,
  className = '',
  contentClassName = '',
  outerContentClassName = '',
  overlayStyles = {},
  contentStyles = {},
  modalFooter = null,
  isFloatingModal = false,
  closeIconColor = '#525353',
  isGameModal = false,
}: Props) => {
  const scale = useResponsiveScale();
  const dimensions = useDimension();

  const customStyles = {
    overlay: {
      // maxWidth: 500,
      // width: `${width}px`,
      width: BaseDimension.width,
      height: BaseDimension.height,
      backgroundColor: 'transparent',
      margin: 'auto',
      ...overlayStyles,
    },
    content: {
      position: 'relative',
      // maxWidth: 500,
      // width: `${width}px`,
      width: BaseDimension.width,
      height: BaseDimension.height,
      outline: 'none',
      appearance: 'none',
      padding: 0,
      overflowY: 'auto',
      marginLeft: 'auto',
      marginRight: 'auto',
      transform: `scale(${scale})`,
      ...contentStyles,
    },
  };

  useEffect(() => {
    if (isOpen) {
      window.scrollTo(0, 0);
    }
    document.body.style.overflow = 'hidden';
    // document.body.style.overflow = isOpen ? 'hidden' : 'auto';
    return () => {
      // document.body.style.overflow = 'auto';
      document.body.style.overflow = 'hidden';
    };
  }, [isOpen]);

  // const preventIOSScroll = (e: any) => {
  //   e.preventDefault();
  // };

  if (isFloatingModal) {
    return (
      <ReactModal
        isOpen={isOpen}
        style={
          {
            overlay: {
              ...customStyles.overlay,
              backgroundColor: 'rgba(0,0,0,0.5)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backdropFilter: 'blur(4px)',
            },
            content: {
              ...customStyles.content,
              width: '70%',
              borderRadius: 16,
              minHeight: 'auto',
              overflowY: 'auto',
              height: 'auto',
            },
          } as any
        }
        className={`flex h-screen flex-col ${className}`}
        parentSelector={() => document.querySelector('#root') as HTMLElement}
        ariaHideApp={false}
      >
        <div className={`flex w-full flex-row justify-center px-2 pt-2 pb-0 ${outerContentClassName}`}>
          {showClose && (
            <button
              type="button"
              className="ml-auto flex h-8 w-8 items-center justify-center rounded-lg active:scale-90 active:bg-ash"
              onClick={onClose}
            >
              <CloseIcon color={closeIconColor} />
            </button>
          )}
        </div>
        <div className={`flex flex-1 flex-col px-4 pb-2 ${contentClassName}`}>{children}</div>
        {!!modalFooter && <div className="flex flex-col justify-center">{modalFooter}</div>}
      </ReactModal>
    );
  }

  if (isGameModal) {
    const gameStyles = {
      width: dimensions.width,
      height: dimensions.height,
      transform: 'none',
      padding: 0,
      margin: 0,
      inset: 0,
      borderRadius: 0,
      border: 'none',
      marginLeft: 'auto',
      marginRight: 'auto',
    };

    return (
      <ReactModal
        isOpen={isOpen}
        style={
          {
            overlay: {
              ...customStyles.overlay,
              ...gameStyles,
            },
            content: {
              ...customStyles.content,
              ...gameStyles,
            },
          } as any
        }
        className={className}
        parentSelector={() => document.querySelector('#root') as HTMLElement}
        ariaHideApp={false}
      >
        <div className={`flex w-full flex-row justify-center px-8 pt-9 pb-0 ${outerContentClassName}`}>
          {showBack && (
            <button
              type="button"
              className="flex h-8 w-8 items-center justify-center rounded-lg active:scale-90 active:bg-ash"
              onClick={onBackClick}
            >
              <BackIcon color={closeIconColor} />
            </button>
          )}
          {showClose && (
            <button
              type="button"
              className="ml-auto flex h-8 w-8 items-center justify-center rounded-lg active:scale-90 active:bg-ash"
              onClick={onClose}
            >
              <CloseIcon color={closeIconColor} />
            </button>
          )}
        </div>
        <div className={`flex flex-1 flex-col ${contentClassName}`}>{children}</div>
        {!!modalFooter && <div className="flex flex-col justify-center">{modalFooter}</div>}
      </ReactModal>
    );
  }

  return (
    <ReactModal
      isOpen={isOpen}
      style={customStyles as any}
      className={className}
      parentSelector={() => document.querySelector('#root') as HTMLElement}
      ariaHideApp={false}
    >
      <div className={`flex w-full flex-row justify-center px-8 pt-9 pb-0 ${outerContentClassName}`}>
        {showBack && (
          <button
            type="button"
            className="flex h-8 w-8 items-center justify-center rounded-lg active:scale-90 active:bg-ash"
            onClick={onBackClick}
          >
            <BackIcon color={closeIconColor} />
          </button>
        )}
        {showClose && (
          <button
            type="button"
            className="ml-auto flex h-8 w-8 items-center justify-center rounded-lg active:scale-90 active:bg-ash"
            onClick={onClose}
          >
            <CloseIcon color={closeIconColor} />
          </button>
        )}
      </div>
      <div className={`flex flex-1 flex-col ${contentClassName}`}>{children}</div>
      {!!modalFooter && <div className="flex flex-col justify-center">{modalFooter}</div>}
    </ReactModal>
  );
};
