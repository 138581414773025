/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import {
  CustomizationKeyType,
  FormInputType,
  IGameCenterAdditionalTerm,
  IGameCenterPlayer,
  IGameCenterPopUp,
  IGameCenterUserBase,
  UserBaseIdentifierType,
} from '@flarie/common';
import {
  checkPlayerIdentifierAction,
  createGameCenterPlayerAction,
  getGameCenterPlayerWithIdentifierAction,
} from 'actions/GameCenterPlayerActions';
import { Button } from 'components/common/button/Button';
import { CheckBox } from 'components/common/check-box/CheckBox';
import { Input } from 'components/common/input/Input';
import { SignInModal } from 'components/sign-in-modal/SignInModal';
import { HTTPRequestType } from 'enums/HTTPRequestType';
import { PlayerErrorType } from 'enums/PlayerErrorType';
import { IGameCenterPlayerDto } from 'interfaces/GameCenterPlayerDto';
import { useEffect, useState } from 'react';
import { acceptLocalTerm, addPlayerVerified } from 'services/LocalStorageService';
import { StyleConverterService } from 'services/StyleConverterService';
import { toastError } from 'services/ToasterServices';
import { useGameCenterSettingStore } from 'store/GameCenterSettingStore';
import { useIngoStore } from 'store/IngoStore';
import { usePlayerStore } from 'store/PlayerStore';
import { getInitialPlayer } from 'utils/get-initial-player';
import { getWebHookEventTypeByUserBase } from 'utils/helper';
import { RenderHtmlText } from 'utils/render-html-text';
import * as uuid from 'uuid';
import isEmail from 'validator/lib/isEmail';
import { shallow } from 'zustand/shallow';
import { TermsModal } from './TermsModal';

interface IAdditionalTermsUserData extends IGameCenterAdditionalTerm {
  checked: boolean;
}

interface Props {
  onSignUpComplete: () => void;
  setIsKnownPlayer: (value: boolean) => void;
}

export const SignInForm = ({ onSignUpComplete, setIsKnownPlayer }: Props) => {
  const [showTermsModal, setShowTermsModal] = useState<boolean>(false);
  const [userBase, detail, popup] = useGameCenterSettingStore(
    (state) => [state.userBase, state.detail, state.popup],
    shallow,
  );
  const [gameCenterPlayer, setGameCenterPlayer] = usePlayerStore(
    (state) => [state.gameCenterPlayer, state.setGameCenterPlayer],
    shallow,
  );

  const [email, setEmail] = useState<string>('');
  const [termsConcent, setTermsConcent] = useState<boolean>(false);
  const [additionalTerms, setAdditionalTerms] = useState<IAdditionalTermsUserData[]>(
    userBase?.additionalTerms.map((item) => ({ ...item, checked: false })) || [],
  );
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [setPlayerCoins, isPlayerError, setPlayerHashId] = usePlayerStore(
    (state) => [state.setPlayerCoins, state.isPlayerError, state.setPlayerHashId],
    shallow,
  );

  const ingoToken = useIngoStore((store) => store.token);

  useEffect(() => {
    if (gameCenterPlayer?.email) {
      setEmail(gameCenterPlayer.email);
    }
    const handleUserCheck = async () => {
      if (userBase && userBase.identifierType === UserBaseIdentifierType.URL_PARAMETER) {
        const identifierValue =
          detail?.customizationKey === CustomizationKeyType.INGO ? ingoToken : gameCenterPlayer?.uuid || '';
        if (!identifierValue) {
          return;
        }
        const result = await getGameCenterPlayerWithIdentifierAction(detail?.gameCenterId as string, identifierValue);
        if (result.data) {
          acceptLocalTerm(detail?.gameCenterId as string, identifierValue);
          setGameCenterPlayer(result.data);
          setPlayerCoins(result.data.coins || 0);
          onSignUpComplete();
          setIsKnownPlayer(true);
        }
      }
    };
    handleUserCheck();
  }, []);

  const handleAdditionalTermsCheck = (name: string) => {
    const updatedTerms = additionalTerms.map((term) => {
      if (term.name === name) {
        return { ...term, checked: !term.checked };
      }
      return term;
    });
    setAdditionalTerms(updatedTerms);
  };
  const createNewGameCenterPlayer = async (identifierValue: string): Promise<void> => {
    const playerId = uuid.v4();
    const player = getInitialPlayer();
    player.email = email;
    player.uuid = gameCenterPlayer?.uuid || '';
    additionalTerms.forEach((item) => {
      player[item.name] = item.checked;
    });
    const { gameCenterId } = userBase as IGameCenterUserBase;
    const payload: IGameCenterPlayerDto = {
      gameCenterId,
      playerId,
      identifierValue,
      verifyEmail: !!email,
      player,
    };
    const webHookQueryParam = getWebHookEventTypeByUserBase(userBase as IGameCenterUserBase, HTTPRequestType.POST);
    const result = await createGameCenterPlayerAction(payload, webHookQueryParam);
    if (result?.data) {
      setGameCenterPlayer(result.data);
      if (userBase?.identifierType === UserBaseIdentifierType.URL_PARAMETER) {
        acceptLocalTerm(gameCenterId, identifierValue);
      } else if (userBase?.identifierType === UserBaseIdentifierType.USER_INPUT) {
        addPlayerVerified(userBase.gameCenterId, result.data.email);
      }
    }
  };

  const onSubmitLoginForm = async (): Promise<void> => {
    try {
      if (isPlayerError === PlayerErrorType.TOKEN_ERROR) {
        toastError('Your token is invalid');
        return;
      }
      if (isPlayerError === PlayerErrorType.URL_ERROR) {
        toastError('Invalid url');
        return;
      }

      // if (additionalTerms.includes(false)) {
      //   const hasInvalid = userBase?.additionalTerms.find(
      //     (data, index) => !(data.isOptional && !additionalTerms[index]),
      //   );
      //   if (hasInvalid) {
      //     return;
      //   }
      // }
      if (userBase?.identifierType === UserBaseIdentifierType.USER_INPUT) {
        const isValidEmail = isEmail(email);
        if (!isValidEmail) {
          setErrorMsg('Invalid e-mail format');
          return;
        }
      }

      let identifierValue = email;
      if (userBase?.identifierType === UserBaseIdentifierType.URL_PARAMETER) {
        identifierValue = gameCenterPlayer?.uuid as string;
      }
      if (detail?.customizationKey === CustomizationKeyType.INGO) {
        identifierValue = ingoToken;
      }
      if (!identifierValue || !userBase) {
        toastError('Player Identifier not found!');
        return;
      }
      if (userBase?.identifierType === UserBaseIdentifierType.URL_PARAMETER) {
        const { data: existingPlayer } = await getGameCenterPlayerWithIdentifierAction(
          userBase.gameCenterId,
          identifierValue,
        );
        if (existingPlayer) {
          setIsKnownPlayer(true);
          setGameCenterPlayer(existingPlayer);
          setPlayerCoins(existingPlayer.coins || 0);
        } else {
          await createNewGameCenterPlayer(identifierValue);
        }
      } else {
        const { data } = await checkPlayerIdentifierAction(userBase.gameCenterId, identifierValue);
        const { playerHashId } = data;
        if (!playerHashId) {
          await createNewGameCenterPlayer(identifierValue);
        } else {
          const payload = {
            gameCenterId: userBase.gameCenterId,
            email: identifierValue,
          };
          setGameCenterPlayer(payload as unknown as IGameCenterPlayer);
          setPlayerHashId(playerHashId);
          setIsKnownPlayer(true);
          addPlayerVerified(userBase.gameCenterId, identifierValue);
        }
      }
      onSignUpComplete();
    } catch (error: any) {
      const errData = error.response.data;
      if (errData.errorType === 'EMAIL_DOMAIN_INVALID') {
        toastError(errData.message);
        return;
      }
      toastError(error?.message);
    }
  };

  if (!userBase) {
    return <div />;
  }

  return (
    <div className="p-8">
      <SignInModal.Title>{userBase.landingPageText}</SignInModal.Title>
      <div className="py-12">
        {userBase.identifierType === UserBaseIdentifierType.USER_INPUT && (
          <Input
            value={email}
            onChange={(value: string) => setEmail(value.toLocaleLowerCase())}
            error={errorMsg}
            placeholder={userBase.parameters?.find(({ type }) => type === FormInputType.EMAIL)?.placeholder || ''}
            style={{
              color: `#${userBase.loginTextColor}`,
              fontSize: '3vh',
              borderBottomColor: `#${userBase.loginTextColor}`,
            }}
            inputColor={`#${userBase.loginTextColor}`}
          />
        )}
      </div>
      <SignInForm.NextButton
        popup={popup as IGameCenterPopUp}
        termsConcent={termsConcent}
        onSubmitLoginForm={onSubmitLoginForm}
      />

      <div className="mx-3">
        <div className="flex flex-row">
          <CheckBox checked={termsConcent} label="" onClick={setTermsConcent} className="mb-6" />
          <span role="button" onClick={() => setShowTermsModal(true)} className="mb-6 mt-1 text-base leading-tight">
            <RenderHtmlText htmlText={`${userBase.loginTermsText}*`} />
          </span>
        </div>
        {additionalTerms.map((termData, index: number) => {
          if (!termData.terms) {
            return <div key={index} />;
          }
          return (
            <CheckBox
              checked={termData.checked}
              label={`${termData.terms}${termData.isOptional ? '' : '*'}`}
              key={termData.terms}
              className="mb-6"
              onClick={() => {
                handleAdditionalTermsCheck(termData.name);
              }}
            />
          );
        })}
      </div>
      {showTermsModal && (
        <TermsModal onClose={() => setShowTermsModal(false)} additionalTermsText={userBase?.additionalTermsText} />
      )}
    </div>
  );
};

SignInForm.NextButton = ({
  popup,
  termsConcent,
  onSubmitLoginForm,
}: {
  popup: IGameCenterPopUp;
  termsConcent: boolean;
  onSubmitLoginForm: () => void;
}) => {
  const styleConverter = new StyleConverterService();
  const convertedNextButtonStyle = styleConverter.convertIStyleToCSS(popup?.nextButtonStyle);

  let buttonStyles: any = {
    ...convertedNextButtonStyle,
    fontFamily: 'inherit',
    width: '100%',
    opacity: 0.6,
  };

  if (termsConcent) {
    buttonStyles = { ...buttonStyles, opacity: 1 };
  }
  return (
    <Button onClick={onSubmitLoginForm} block className="mt-5 mb-16" disabled={!termsConcent} style={buttonStyles}>
      {popup?.nextButtonText || 'Next'}
    </Button>
  );
};
