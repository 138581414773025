/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  AttemptGamificationLockType,
  defaultDateFormat,
  GameCenterModuleLockType,
  GamificationType,
  IGameCenterGame,
  Size,
} from '@flarie/common';
import { Modal } from 'components/common/modal/Modal';
import Lock from 'components/lock/Lock';
import ClosePopup from 'components/popup/ClosePopup';
import useGameCenterPlayer from 'hooks/useGameCenterPlayer';
import { FC, useState } from 'react';
import { useGameCenterSettingStore } from 'store/GameCenterSettingStore';
import { usePlayerStore } from 'store/PlayerStore';
import { shallow } from 'zustand/shallow';
import dayjs from 'dayjs';
import { getLocalTime } from 'utils/get-local-time';
import Timezone from 'timezone-enum';
import useGameCenterData from 'hooks/useGameCenterData';
import GameDetails from './GameDetails';
import { getGameStyle } from './styles';

const Game: FC<IGameCenterGame> = ({
  gameImage,
  isGameLock,
  unLockDays,
  gameImageBackgroundStyle,
  gameSize,
  game,
  gameId,
  gameLockImage,
  isStartDateCountDown,
  startDate,
  countDownImage,
  id,
  attemptGamificationLockType,
  gamificationId,
  gamificationType,
}) => {
  const styles = getGameStyle({ gameImageBackgroundStyle });
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openLockModal, setOpenLockModal] = useState<boolean>(false);
  const [popup, userBase, detail, moduleLock] = useGameCenterSettingStore(
    (state) => [state.popup, state.userBase, state.detail, state.moduleLock],
    shallow,
  );

  const gameCenterPlayer = usePlayerStore((state) => state.gameCenterPlayer);

  const { refetchGameCenterPlayer } = useGameCenterPlayer(
    userBase?.gameCenterId as string,
    userBase?.isEnable ? userBase?.identifierType : undefined,
  );

  const { isFetching } = useGameCenterData(
    userBase?.gameCenterId as string,
    userBase?.isEnable ? userBase?.identifierType : undefined,
  );

  const onCloseModal = () => {
    refetchGameCenterPlayer();
    setOpenModal(false);
  };

  const onCloseLockModal = () => {
    setOpenLockModal(false);
  };
  const foundGameLock = () => {
    const foundLock = moduleLock.games.find(
      (item) =>
        item.id === id &&
        (item.type === GameCenterModuleLockType.ADVENTURE_GAMIFICATION ||
          (item.type === GameCenterModuleLockType.ATTEMPT_GAMIFICATION &&
            attemptGamificationLockType === AttemptGamificationLockType.LOCK)),
    );
    return foundLock;
  };

  const getLock = () => {
    if (gameId) return null;

    if (isStartDateCountDown && startDate && dayjs(getLocalTime(startDate, detail?.timeZone as Timezone)) > dayjs()) {
      const localStartDate = dayjs(getLocalTime(startDate, detail?.timeZone as Timezone));
      const days = localStartDate.diff(dayjs(), 'day');
      const incrementedStartDate = localStartDate.subtract(days, 'day').format(defaultDateFormat);
      return <Lock days={days} countDownDate={incrementedStartDate as string} backgroundImageUrl={countDownImage} />;
    }

    if (isGameLock && gameCenterPlayer?.PK) {
      return (
        <Lock
          days={unLockDays}
          countDownDate={gameCenterPlayer?.createdAt as string}
          backgroundImageUrl={gameLockImage}
        />
      );
    }

    const foundLock = foundGameLock();
    if (foundGameLock()) {
      return <Lock days={foundLock?.unLockDays} backgroundImageUrl={gameLockImage} />;
    }

    return null;
  };

  const shouldHideGame = () => {
    return (
      gamificationId &&
      gamificationType === GamificationType.ATTEMPT &&
      moduleLock.games.some(
        (gameLock) =>
          gameLock.id === id &&
          gameLock.type === GameCenterModuleLockType.ATTEMPT_GAMIFICATION &&
          attemptGamificationLockType === AttemptGamificationLockType.HIDE,
      )
    );
  };

  const onOpenModal = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    if (getLock()) {
      setOpenLockModal(true);
    } else {
      setOpenModal(true);
    }
  };

  const shouldHideGamificationGame = (): boolean => {
    return (
      !gameCenterPlayer?.PK &&
      Boolean(gamificationId) &&
      gamificationType === GamificationType.ATTEMPT &&
      [AttemptGamificationLockType.HIDE, AttemptGamificationLockType.LOCK].includes(
        attemptGamificationLockType as AttemptGamificationLockType,
      )
    );
  };

  if (!userBase) return <div />;

  if (shouldHideGamificationGame() || shouldHideGame()) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  return (
    <>
      <a
        href="#"
        className={`mb-4 text-inherit ${gameSize === Size.FULL ? 'col-span-2' : ''}`}
        onClick={(e) => onOpenModal(e)}
      >
        <div className="relative h-full overflow-hidden" style={styles.gameImageBackgroundStyle}>
          <div className="flex h-full w-full items-center">
            {!isFetching && <img className="ml-auto mr-auto w-full" src={gameImage} alt="Game" />}
          </div>
          {getLock()}
        </div>
      </a>

      <Modal
        isOpen={openLockModal}
        onClose={onCloseLockModal}
        showFooter={false}
        showBack={false}
        showClose={false}
        contentClassName="justify-center !pt-0 !bg-black/[.70]"
        outerContentClassName="!pt-0"
      >
        <ClosePopup onCloseModal={onCloseLockModal} popupText={foundGameLock()?.message || popup?.coolDownText || ''} />
      </Modal>

      <Modal
        isOpen={openModal}
        onClose={onCloseModal}
        showFooter={false}
        showBack={false}
        showClose={false}
        isGameModal
      >
        <GameDetails game={game} gameId={gameId} onClose={onCloseModal}></GameDetails>
      </Modal>
    </>
  );
};
export default Game;
