/* eslint-disable react/jsx-no-useless-fragment */
import { IGameCenterPopUp, IGameCenterUserBase } from '@flarie/common';
import { Modal } from 'components/common/modal/Modal';
import { PlayerAuthComplete } from 'components/player-auth-complete/PlayerAuthComplete';
import { PlayerParameterForm } from 'components/player-parameter-form/PlayerParameterForm';
import { PlayerVerificationForm } from 'components/player-verification-form/PlayerVerificationForm';
import ClosePopup from 'components/popup/ClosePopup';
import { SignInModal } from 'components/sign-in-modal/SignInModal';
import { GameCenterSettingsPreviewType } from 'enums/GameCenterSettingsPreviewType';
import { useActiveSettingPreviewStore } from 'store/ActiveSettingPreviewStore';
import { useGameCenterSettingStore } from 'store/GameCenterSettingStore';
import { shallow } from 'zustand/shallow';

const gameCenterSettingsPreviewStack = {
  [GameCenterSettingsPreviewType.LANDING_SIGN_IN_UP_PAGE]: <SignInModal isOpen={true} onClose={() => {}} />,
  [GameCenterSettingsPreviewType.ADDITIONAL_FORM]: (
    <div className="p-8">
      <PlayerParameterForm />
    </div>
  ),
  [GameCenterSettingsPreviewType.SIGN_IN_CODE]: (
    <div className="p-8">
      <PlayerVerificationForm onCloseModal={() => {}} />
    </div>
  ),
  [GameCenterSettingsPreviewType.SIGN_UP_VERIFICATION]: (
    <div className="p-8">
      <PlayerAuthComplete onCloseModal={() => {}} />
    </div>
  ),
};

export const GameCenterSettingsPreview = (): JSX.Element => {
  const { selectedPreview } = useActiveSettingPreviewStore();
  const [popup, userBase] = useGameCenterSettingStore((state) => [state.popup, state.userBase], shallow);

  if (!selectedPreview || !userBase || !popup) {
    return <></>;
  }

  return (
    <>
      <GameCenterSettingsPreview.UserBaseModalPreview selectedPreview={selectedPreview} userBase={userBase} />
      <GameCenterSettingsPreview.PopupPreview
        selectedPreview={selectedPreview}
        popup={popup}
      ></GameCenterSettingsPreview.PopupPreview>
    </>
  );
};

// Sub component for rendering userBase previews
GameCenterSettingsPreview.UserBaseModalPreview = ({
  selectedPreview,
  userBase,
}: {
  selectedPreview: GameCenterSettingsPreviewType;
  userBase: IGameCenterUserBase;
}) => {
  if (
    ![
      GameCenterSettingsPreviewType.SIGN_IN_CODE,
      GameCenterSettingsPreviewType.ADDITIONAL_FORM,
      GameCenterSettingsPreviewType.SIGN_UP_VERIFICATION,
      GameCenterSettingsPreviewType.LANDING_SIGN_IN_UP_PAGE,
    ].includes(selectedPreview)
  ) {
    return <></>;
  }
  return (
    <Modal
      isOpen={true}
      onClose={() => {}}
      className="text-dark"
      contentStyles={{ backgroundColor: `#${userBase.loginBackgroundColor}`, color: `#${userBase.loginTextColor}` }}
      modalFooter={<img src={userBase.logoImage} alt="Logo" className="object-contain" />}
      closeIconColor={`#${userBase.loginTextColor}`}
    >
      {(gameCenterSettingsPreviewStack as any)[selectedPreview]}
    </Modal>
  );
};

// Sub component for rendering popup previews
GameCenterSettingsPreview.PopupPreview = ({
  selectedPreview,
  popup,
}: {
  selectedPreview: GameCenterSettingsPreviewType;
  popup: IGameCenterPopUp;
}) => {
  if (
    ![
      GameCenterSettingsPreviewType.PURCHASE_ITEM,
      GameCenterSettingsPreviewType.PURCHASE_COMPLETED,
      GameCenterSettingsPreviewType.ERROR,
      GameCenterSettingsPreviewType.COOLDOWN,
      GameCenterSettingsPreviewType.NOT_ENOUGH_COINS,
      GameCenterSettingsPreviewType.PRIZE_OUT_OF_STOCK,
      GameCenterSettingsPreviewType.CURRENCY_COOLDOWN,
    ].includes(selectedPreview)
  ) {
    return <></>;
  }
  const getPopupText = (): string => {
    const {
      prizePurchaseItemText,
      prizePurchaseICompletedText,
      errorText,
      coolDownText,
      notEnoughCoinsText,
      prizeOutOfStockText,
      currencyCoolDownText,
    } = popup;

    switch (selectedPreview) {
      case GameCenterSettingsPreviewType.PURCHASE_ITEM:
        return prizePurchaseItemText;
      case GameCenterSettingsPreviewType.PURCHASE_COMPLETED:
        return prizePurchaseICompletedText;
      case GameCenterSettingsPreviewType.ERROR:
        return errorText;
      case GameCenterSettingsPreviewType.COOLDOWN:
        return coolDownText;
      case GameCenterSettingsPreviewType.NOT_ENOUGH_COINS:
        return notEnoughCoinsText;
      case GameCenterSettingsPreviewType.PRIZE_OUT_OF_STOCK:
        return prizeOutOfStockText;
      case GameCenterSettingsPreviewType.CURRENCY_COOLDOWN:
        return currencyCoolDownText;
      default:
        return '';
    }
  };

  return (
    <Modal
      isOpen={true}
      onClose={() => {}}
      showFooter={false}
      showBack={false}
      showClose={false}
      contentClassName="justify-center !pt-0 !bg-black/[.70]"
      outerContentClassName="!pt-0"
    >
      <ClosePopup onCloseModal={() => {}} popupText={getPopupText()} />
    </Modal>
  );
};
