import { BaseDimension } from 'common/constants';
import { useEffect, useState } from 'react';

export const useResponsiveScale = () => {
  const [scale, setScale] = useState(1);
  const { width, height } = BaseDimension;

  useEffect(() => {
    const updateScale = () => {
      const vw = window.innerWidth;
      const vh = window.innerHeight;

      // Scale factor based on width or height (whichever fits best)
      const scaleFactor = Math.min(vw / width, vh / height);
      setScale(scaleFactor);
    };

    updateScale(); // Initial Scaling

    window.addEventListener('resize', updateScale);
    return () => window.removeEventListener('resize', updateScale);
  }, []);

  return scale;
};
