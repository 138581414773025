import { FormInputType, IGameCenterFormInput, IGameCenterPlayer } from '@flarie/common';
import { getPropertyKeyFromType } from './get-property-key-from-type';

export const checkKnownPlayer = (
  gameCenterData: IGameCenterPlayer,
  userParameters: IGameCenterFormInput[],
): boolean => {
  const invalidValue = userParameters.find(
    ({ type }: IGameCenterFormInput) =>
      !(gameCenterData as any)[getPropertyKeyFromType(type)] && type !== FormInputType.CONSENT,
  );
  return !invalidValue;
};
